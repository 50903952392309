/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import BrandsSortByLetters from './BrandsSortByLetters.component';
import { BUTTON_ALL_BRANDS_TITLE } from './BrandsSortByLetters.config';

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandsSortByLetters/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    brandList: state.ConfigReducer.brandsConfig.ambrandlist
});

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandsSortByLetters/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandsSortByLetters/Container/BrandsSortByLettersContainer */
export class BrandsSortByLettersContainer extends PureComponent {
    static propTypes = {
        brandList: PropTypes.shape({
            all_letters: PropTypes.string
        }).isRequired,
        layout: PropTypes.string.isRequired,
        setSort: PropTypes.func.isRequired,
        filter: PropTypes.string.isRequired,
        isFilterDisplayAll: PropTypes.bool
    };

    static defaultProps = {
        isFilterDisplayAll: true
    };

    containerFunctions = {
        handleLetterClick: this.handleLetterClick.bind(this)
    };

    containerProps() {
        const {
            brandList: {
                all_letters: activeLetters
            } = {},
            filter,
            layout,
            isFilterDisplayAll
        } = this.props;

        return {
            activeLetters,
            filter,
            layout,
            isFilterDisplayAll
        };
    }

    handleLetterClick(letter) {
        const {
            setSort,
            brandList: {
                all_letters: activeLetters
            }
        } = this.props;

        const activeLettersArray = Array.from(activeLetters);
        activeLettersArray.push(BUTTON_ALL_BRANDS_TITLE);

        activeLettersArray.find((e) => {
            if (e === letter) {
                return setSort(letter);
            }

            return null;
        });
    }

    render() {
        return (
            <BrandsSortByLetters
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandsSortByLettersContainer);
