/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { MixType } from 'SourceType/Common.type';

import BrandsLetter from './BrandsLetter.component';

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandsLetter/Container */
export class BrandsLetterContainer extends PureComponent {
    static propTypes = {
        isActive: PropTypes.bool.isRequired,
        mix: MixType,
        onClick: PropTypes.func.isRequired,
        value: PropTypes.string.isRequired,
        isSelected: PropTypes.bool
    };

    static defaultProps = {
        mix: {},
        isSelected: false
    };

    containerFunctions = {
        handleClick: this.handleClick.bind(this)
    };

    containerProps() {
        const {
            isActive,
            value,
            mix,
            isSelected
        } = this.props;

        return {
            isActive,
            value,
            mix,
            isSelected
        };
    }

    handleClick(e) {
        e.stopPropagation();

        const {
            onClick,
            value
        } = this.props;

        onClick(value);
    }

    render() {
        return (
            <BrandsLetter
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default BrandsLetterContainer;
