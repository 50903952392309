/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable no-nested-ternary */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { BrandSliderItemType, BrandSliderType } from '../../type/BrandsType';
import { getCurrentStoreBrandsConfig } from '../../util/Brands';
import BrandSlider from './BrandSlider.component';

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandSlider/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    brandSlider: state.ConfigReducer.brandsConfig.ambrandslider,
    isMobile: state.ConfigReducer.device.isMobile,
    device: state.ConfigReducer.device,
    storeCode: state.ConfigReducer.code,
    config: state.ConfigReducer.brandsConfig
});

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandSlider/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
});

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandSlider/Container */
export class BrandSliderContainer extends PureComponent {
    static propTypes = {
        brandSlider: PropTypes.shape({
            items: PropTypes.arrayOf(BrandSliderItemType)
        }).isRequired,
        ...BrandSliderType
    };

    containerProps() {
        const {
            sliderTitle,
            itemsNumber,
            brandSlider: { items = {} } = {},
            sortBy,
            storeCode,
            config: {
                availableStores = []
            } = {}
        } = this.props;

        const {
            amshopby_brand_general_brands_page
        } = getCurrentStoreBrandsConfig(availableStores, storeCode);

        const sortedItems = sortBy === 'position'
            ? items.sort((a, b) => Number(b.position) - Number(a.position))
            : items;

        return {
            items: sortedItems,
            itemsNumber,
            sliderTitle,
            brandsPageUrl: amshopby_brand_general_brands_page
        };
    }

    render() {
        return (
            <BrandSlider { ...this.containerProps() } />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandSliderContainer);
