/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import Link from '@scandipwa/scandipwa/src/component/Link';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { appendWithStoreCode } from 'Util/Url';

import { BrandSliderItemType, BrandSliderType } from '../../type/BrandsType';

import './BrandSlider.style';

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandSlider/Component */
export class BrandSliderComponent extends PureComponent {
    static propTypes = {
        ...BrandSliderType,
        sliderTitle: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(BrandSliderItemType).isRequired,
        brandsPageUrl: PropTypes.string.isRequired
    };

    renderBrandSliderLabel() {
        const { sliderTitle } = this.props;

        return (
            <h2 block="BrandSlider" elem="Header">
            { sliderTitle }
            </h2>
        );
    }

    renderBrandSliderItems() {
        const { items, itemsNumber } = this.props;

        if (items.length === 0) {
            return this.renderBrandSliderPlaceholder();
        }

        return (
            <div block="BrandSlider" elem="ItemList">
                { items
                    .slice(0, itemsNumber)
                    .map((item) => this.renderBrandSliderItem(item)) }
            </div>
        );
    }

    renderBrandSliderItem({ label, url, img }) {
        return (
            <Link
              key={ label }
              to={ url }
              block="BrandSlider"
              elem="Item"
              style={ { backgroundImage: `url("${img}")` } }
            />
        );
    }

    renderBrandSliderPlaceholder() {
        return (
            <div block="BrandSlider" elem="Placeholder">
                <span block="BrandSlider" elem="Message">
                    { __('No featured brands added.') }
                </span>
            </div>
        );
    }

    renderBrandSliderCTA() {
        const { brandsPageUrl } = this.props;

        return (
            <Link to={ appendWithStoreCode(brandsPageUrl) } block="BrandSlider" elem="CTA">
                { __('See all brands') }
            </Link>
        );
    }

    render() {
        return (
            <div block="BrandSlider">
                { this.renderBrandSliderLabel() }
                { this.renderBrandSliderItems() }
                { this.renderBrandSliderCTA() }
            </div>
        );
    }
}

export default BrandSliderComponent;
