/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { hideTooltip, showTooltip } from '../../store/BrandTooltip/BrandTooltip.action';
import { BrandsType } from '../../type/BrandsType';
import BrandsListItem from './BrandsListItem.component';
/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandsListItem/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({});

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandsListItem/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showTooltip: (itemRef, isShowImages, description, image) => dispatch(
        showTooltip(itemRef, isShowImages, description, image)
    ),
    hideTooltip: () => dispatch(hideTooltip())
});

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandsListItem/Container */
export class BrandsListItemContainer extends PureComponent {
    static propTypes = {
        brand: BrandsType.isRequired,
        hideTooltip: PropTypes.func.isRequired,
        imageHeight: PropTypes.number,
        imageWidth: PropTypes.number.isRequired,
        layout: PropTypes.string,
        isShowCount: PropTypes.bool.isRequired,
        isShowImages: PropTypes.bool.isRequired,
        showTooltip: PropTypes.func.isRequired
    };

    static defaultProps = {
        imageHeight: undefined,
        layout: 'WIDGET_LAYOUT'
    };

    containerFunctions = {
        showTooltip: this.showTooltip.bind(this),
        hideTooltip: this.hideTooltip.bind(this)
    };

    containerProps() {
        const {
            isShowImages,
            imageHeight: imageHeightOptional,
            imageWidth,
            brand,
            isShowCount,
            layout
        } = this.props;

        // ↓↓↓ height is equal to width in case when image height is not set in BE
        const imageHeight = imageHeightOptional || imageWidth;

        return {
            brand,
            isShowImages,
            imageWidth,
            imageHeight,
            isShowCount,
            layout
        };
    }

    showTooltip(itemRef) {
        const {
            showTooltip,
            isShowImages: showImagesInt,
            brand: {
                short_description,
                image
            }
        } = this.props;

        showTooltip(
            itemRef,
            !!showImagesInt,
            short_description,
            image
        );
    }

    hideTooltip() {
        const { hideTooltip } = this.props;

        hideTooltip();
    }

    render() {
        return (
            <BrandsListItem
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandsListItemContainer);
