/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import BrandsSearch from './BrandsSearch.component';

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandsSearch/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({});

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandsSearch/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandsSearch/Container/BrandsSearchContainer */
export class BrandsSearchContainer extends PureComponent {
    static propTypes = {
        setSearch: PropTypes.func.isRequired
    };

    containerFunctions = {
        handleChange: this.handleChange.bind(this)
    };

    containerProps() {}

    handleChange({ target: { value: searchTerm } }) {
        const { setSearch } = this.props;

        setSearch(searchTerm);
    }

    render() {
        return (
            <BrandsSearch
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandsSearchContainer);
