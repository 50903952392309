/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */

import RenderWhenVisible from 'Component/RenderWhenVisible';

import BrandSlider from '../../component/BrandSlider';
import { BRAND_SLIDER_CMS_TYPE } from '../../component/BrandSlider/BrandSlider.config';
import BrandsList from '../../component/BrandsList';
import { BRAND_LIST_CMS_TYPE } from '../../component/BrandsList/BrandList.config';

const renderMap = (member) => ({
    ...member,
    [BRAND_LIST_CMS_TYPE]: {
        component: BrandsList
    },
    [BRAND_SLIDER_CMS_TYPE]: {
        component: BrandSlider
    }
});

const renderContent = (args, callback, instance) => {
    const { type } = instance.props;

    const {
        component: Widget,
        fallback
    } = instance.renderMap[type] || {};

    if (Widget !== undefined) {
        return (
            <RenderWhenVisible fallback={ fallback }>
                <Widget { ...instance.props } />
            </RenderWhenVisible>
        );
    }

    return null;
};

export default {
    'Component/WidgetFactory/Component': {
        'member-function': {
            renderContent
        },
        'member-property': {
            renderMap
        }
    }
};
