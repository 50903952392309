/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

// import {
//     GoogleApiWrapper as withGoogleApiWrapper,
//     InfoWindow,
//     Map,
//     Marker
// } from 'google-maps-react';
// import PropTypes from 'prop-types';
// import { PureComponent } from 'react';

// import { generateBounds } from './Map.config';

// /** @namespace Scandiweb/AdobePageBuilder/Component/Map/Component */
// export class MapComponent extends PureComponent {
//     static propTypes = {
//         // eslint-disable-next-line react/forbid-prop-types
//         google: PropTypes.any.isRequired,
//         element: PropTypes.element.isRequired,
//         locations: PropTypes.arrayOf(PropTypes.shape({
//             location_name: PropTypes.string,
//             comment: PropTypes.string,
//             phone: PropTypes.string,
//             address: PropTypes.string,
//             areaAddress: PropTypes.string,
//             country: PropTypes.string
//         })).isRequired,
//         options: PropTypes.shape({}).isRequired,
//         zoom: PropTypes.number.isRequired
//     };

//     __construct(props) {
//         super.__construct(props);

//         this.state = {
//             showingInfoWindow: false,
//             activeMarker: {},
//             activeLocationIdx: undefined
//         };
//     }

//     onMarkerClick = ({ marker }, index) => this.setState({
//         activeMarker: marker,
//         activeLocationIdx: index,
//         showingInfoWindow: true
//     });

//     onMapClicked = () => {
//         const { showingInfoWindow } = this.state;

//         if (!showingInfoWindow) {
//             return;
//         }

//         this.setState({
//             showingInfoWindow: false,
//             activeMarker: null
//         });
//     };

//     renderMarker = (location, index) => {
//         const { location_name: name, position } = location;

//         return (
//             <Marker
//               key={ JSON.stringify(position) }
//               name={ name }
//               title={ name }
//               position={ position }
//               // eslint-disable-next-line react/jsx-no-bind
//               onClick={ (_props, marker) => this.onMarkerClick({ marker }, index) }
//             />
//         );
//     };

//     renderLocation() {
//         const { locations } = this.props;
//         const { activeLocationIdx } = this.state;

//         const {
//             location_name,
//             comment,
//             phone,
//             address,
//             areaAddress,
//             country
//         } = locations[activeLocationIdx];

//         return (
//             <div>
//                 <h3>{ location_name }</h3>
//                 <p>{ comment }</p>
//                 <p>{ __('Phone: %s', phone) }</p>
//                 <p>
//                     <span>
//                         { address }
//                         <br />
//                         { areaAddress }
//                         <br />
//                         { country }
//                     </span>
//                 </p>
//             </div>
//         );
//     }

//     renderInfoWindow() {
//         const { locations } = this.props;

//         const {
//             activeLocationIdx,
//             showingInfoWindow,
//             activeMarker
//         } = this.state;

//         const activeLocation = locations[activeLocationIdx];

//         if (!activeLocation) {
//             return null;
//         }

//         return (
//             <InfoWindow marker={ activeMarker } visible={ showingInfoWindow }>
//                 { this.renderLocation() }
//             </InfoWindow>
//         );
//     }

//     renderMarkers() {
//         const { locations } = this.props;
//         return locations.map(this.renderMarker);
//     }

//     onMapMount = (mapRef) => {
//         if (!mapRef) {
//             return;
//         }

//         const { map } = mapRef;
//         const { google, locations } = this.props;
//         map.fitBounds(generateBounds(google, locations));
//     };

//     render() {
//         const {
//             google,
//             element: Element,
//             options,
//             zoom
//         } = this.props;

//         return (
//             <Element>
//                 <Map
//                   ref={ this.onMapMount }
//                   options={ options }
//                   google={ google }
//                   zoom={ zoom }
//                   onClick={ this.onMapClicked }
//                 >
//                     { this.renderMarkers() }
//                     { this.renderInfoWindow() }
//                 </Map>
//             </Element>
//         );
//     }
// }

// export default withGoogleApiWrapper(({ apiKey }) => ({ apiKey }))(MapComponent);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, LoadScript, InfoWindow, Marker } from '@react-google-maps/api';

import { generateBounds } from './Map.config'; // Ensure this function is compatible with the new API

/** @namespace Scandiweb/AdobePageBuilder/Component/Map/Component */
export class MapComponent extends PureComponent {
    static propTypes = {
        element: PropTypes.element.isRequired,
        locations: PropTypes.arrayOf(PropTypes.shape({
            location_name: PropTypes.string,
            comment: PropTypes.string,
            phone: PropTypes.string,
            address: PropTypes.string,
            areaAddress: PropTypes.string,
            country: PropTypes.string,
            position: PropTypes.shape({
                lat: PropTypes.number.isRequired,
                lng: PropTypes.number.isRequired
            }).isRequired
        })).isRequired,
        options: PropTypes.object.isRequired,
        zoom: PropTypes.number.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            showingInfoWindow: false,
            activeMarker: null,
            activeLocationIdx: null
        };
    }

    onMarkerClick = (location, index) => {
        this.setState({
            activeMarker: location.position,
            activeLocationIdx: index,
            showingInfoWindow: true
        });
    };

    onMapClick = () => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    };

    renderMarker = (location, index) => (
        <Marker
            key={JSON.stringify(location.position)}
            position={location.position}
            onClick={() => this.onMarkerClick(location, index)}
        />
    );

    renderLocation() {
        const { locations } = this.props;
        const { activeLocationIdx } = this.state;

        if (activeLocationIdx === null) return null;

        const {
            location_name,
            comment,
            phone,
            address,
            areaAddress,
            country
        } = locations[activeLocationIdx];

        return (
            <div>
                <h3>{location_name}</h3>
                <p>{comment}</p>
                <p>{`Phone: ${phone}`}</p>
                <p>
                    {address}
                    <br />
                    {areaAddress}
                    <br />
                    {country}
                </p>
            </div>
        );
    }

    renderInfoWindow() {
        const { showingInfoWindow, activeMarker } = this.state;
        const { locations } = this.props;

        if (!activeMarker) return null;

        const activeLocation = locations[this.state.activeLocationIdx];

        return (
            <InfoWindow
                position={activeMarker}
                onCloseClick={() => this.setState({ showingInfoWindow: false })}
            >
                {this.renderLocation()}
            </InfoWindow>
        );
    }

    renderMarkers() {
        const { locations } = this.props;
        return locations.map(this.renderMarker);
    }

    onMapLoad = (map) => {
        if (map) {
            const bounds = generateBounds(map); // Ensure this function is compatible
            map.fitBounds(bounds);
        }
    };

    render() {
        const {
            element: Element,
            options,
            zoom
        } = this.props;

        return (
            <LoadScript googleMapsApiKey="YOUR_API_KEY">
                <GoogleMap
                    mapContainerStyle={{ height: '100%', width: '100%' }}
                    zoom={zoom}
                    options={options}
                    onClick={this.onMapClick}
                    onLoad={this.onMapLoad}
                >
                    {this.renderMarkers()}
                    {this.renderInfoWindow()}
                </GoogleMap>
            </LoadScript>
        );
    }
}

export default MapComponent;