/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable jsx-a11y/click-events-have-key-events */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { MixType } from 'SourceType/Common.type';

import './BrandsLetter.style';

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandsLetter/Component/BrandsLetterComponent */
export class BrandsLetterComponent extends PureComponent {
    static propTypes = {
        handleClick: PropTypes.func.isRequired,
        isActive: PropTypes.bool.isRequired,
        isSelected: PropTypes.bool,
        mix: MixType,
        value: PropTypes.string.isRequired
    };

    static defaultProps = {
        mix: {},
        isSelected: false
    };

    render() {
        const {
            isActive,
            value,
            mix,
            handleClick,
            isSelected
        } = this.props;

        return (
            <div
              block="BrandsLetter"
              mods={ { isActive, isSelected } }
              mix={ mix }
              onClick={ handleClick }
              role="button"
              tabIndex="0"
            >
                { value }
            </div>
        );
    }
}

export default BrandsLetterComponent;
