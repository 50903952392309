/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { containAnyLetter } from '../../util/Brands';
import BrandsLetter from '../BrandsLetter';
import { WIDGET_LAYOUT } from '../BrandsList/BrandList.config';
import { ALL_LETTERS, BUTTON_ALL_BRANDS_TITLE } from './BrandsSortByLetters.config';

import './BrandsSortByLetters.style';

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandsSortByLetters/Component/BrandsSortByLettersComponent */
export class BrandsSortByLettersComponent extends PureComponent {
    static propTypes = {
        activeLetters: PropTypes.string.isRequired,
        handleLetterClick: PropTypes.func.isRequired,
        layout: PropTypes.string.isRequired,
        filter: PropTypes.string.isRequired,
        // eslint-disable-next-line react/boolean-prop-naming
        isFilterDisplayAll: PropTypes.bool
    };

    static defaultProps = {
        isFilterDisplayAll: true
    };

    renderAllBrands() {
        const {
            handleLetterClick,
            filter,
            layout
        } = this.props;

        return (
            <BrandsLetter
              onClick={ handleLetterClick }
              value={ BUTTON_ALL_BRANDS_TITLE }
              isActive // <- All brands button should be always highlighted and clickable
              isSelected={ filter === BUTTON_ALL_BRANDS_TITLE }
              mix={ { block: 'BrandsSortByLetters', mods: { layout } } }
            />
        );
    }

    renderLetters() {
        const {
            activeLetters,
            handleLetterClick,
            filter,
            layout,
            isFilterDisplayAll
        } = this.props;

        return Array.from(ALL_LETTERS, (letter) => {
            const isActive = containAnyLetter(letter, activeLetters);
            const isSelected = filter === letter;

            // ↓↓↓ Hide gray letters if they are turned off in BE
            if (layout === WIDGET_LAYOUT && !isActive && !isFilterDisplayAll) {
                return null;
            }

            return (
                <BrandsLetter
                  key={ letter }
                  onClick={ handleLetterClick }
                  value={ letter }
                  isActive={ isActive }
                  isSelected={ isSelected }
                />
            );
        });
    }

    render() {
        return (
            <div block="BrandsSortByLetters" elem="Wrapper">
                { this.renderAllBrands() }
                { this.renderLetters() }
            </div>
        );
    }
}

export default BrandsSortByLettersComponent;
