/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { BrandsType } from '../../type/BrandsType';
import { filterBrandListBySearchAndLetter, filterLetters } from '../../util/Brands';
import {
    BRAND_LIST_DEFAULT_FILTER,
    BRAND_LIST_DEFAULT_TYPE,
    DEFAULT_COLUMN_COUNT,
    DEFAULT_IMAGE_HEIGHT,
    DEFAULT_IMAGE_WIDTH,
    WIDGET_LAYOUT
} from './BrandList.config';
import BrandsList from './BrandsList.component';

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandsList/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    brandListConfig: state.ConfigReducer.brandsConfig.ambrandlist,
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandsList/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandsList/Container */
export class BrandsListContainer extends PureComponent {
    static propTypes = {
        brandListConfig: PropTypes.shape({
            items: PropTypes.arrayOf(
                BrandsType
            ),
            all_letters: PropTypes.string
        }).isRequired,
        isMobile: PropTypes.bool.isRequired,
        isMenuOverlay: PropTypes.bool,
        layout: PropTypes.string,
        columns: PropTypes.number,
        displayZero: PropTypes.number,
        filterDisplayAll: PropTypes.number,
        imageHeight: PropTypes.number,
        imageWidth: PropTypes.number,
        showCount: PropTypes.number,
        showFilter: PropTypes.number,
        showImages: PropTypes.number,
        showSearch: PropTypes.number,
        type: PropTypes.string
    };

    static defaultProps = {
        layout: WIDGET_LAYOUT,
        isMenuOverlay: false,
        columns: DEFAULT_COLUMN_COUNT,
        displayZero: false,
        filterDisplayAll: true,
        imageHeight: DEFAULT_IMAGE_HEIGHT,
        imageWidth: DEFAULT_IMAGE_WIDTH,
        showCount: false,
        showFilter: true,
        showImages: true,
        showSearch: false,
        type: BRAND_LIST_DEFAULT_TYPE
    };

    containerFunctions = {
        setSearch: this.setSearch.bind(this),
        setSort: this.setSort.bind(this)
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            search: '',
            filter: BRAND_LIST_DEFAULT_FILTER
        };
        // ↑↑↑ filter by 'all brands' by default
    }

    containerProps() {
        const {
            columns,
            displayZero,
            filterDisplayAll: isFilterDisplayAll,
            imageWidth,
            showCount: isShowCount,
            showFilter: isShowFilter,
            showImages: isShowImages,
            showSearch: isShowSearch,
            type,
            brandListConfig: {
                items: allBrands = [],
                all_letters: allLettersString = ''
            } = {},
            layout,
            isMobile
        } = this.props;

        const { search, filter } = this.state;

        const params = {
            columns,
            displayZero,
            isFilterDisplayAll,
            imageWidth,
            isShowCount,
            isShowFilter,
            isShowImages,
            isShowSearch,
            type
        };

        // ↓↓↓ convert value from backend (example: 'A,B,E,F,G') to array
        const allLetters = allLettersString?.split(',').sort();

        const isFiltered = search.length > 0 || filter !== 'all brands';
        const brandList = isFiltered ? filterBrandListBySearchAndLetter(allBrands, search, filter) : allBrands;
        const lettersWithBrand = isFiltered > 0 ? filterLetters(brandList) : allLetters;

        // ↓↓↓ convert letter array to chunks by column count to [A,B,E,F], [B,D,H,K] (if column count is 4)
        const lettersWithBrandColumns = lettersWithBrand.map((e, i) => (
            i % params.columns === 0 ? lettersWithBrand.slice(i, i + params.columns) : null
        )).filter((e) => e);

        return {
            params,
            brandList,
            lettersWithBrandColumns,
            filter,
            layout,
            isMobile
        };
    }

    setSearch(searchTerm) {
        this.setState({ search: searchTerm });
    }

    setSort(letter) {
        this.setState({ filter: letter });
    }

    render() {
        return (
            <BrandsList
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandsListContainer);
