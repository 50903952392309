/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';

export const BrandSliderType = PropTypes.shape({
    itemsNumber: PropTypes.number,
    displayZero: PropTypes.number,
    sortBy: PropTypes.string,
    sliderWidth: PropTypes.number,
    sliderTitle: PropTypes.string,
    sliderHeaderColor: PropTypes.string,
    sliderTitleColor: PropTypes.string,
    imageWidth: PropTypes.number,
    imageHeight: PropTypes.number,
    showLabel: PropTypes.number,
    buttonsShow: PropTypes.number,
    infinityLoop: PropTypes.number,
    simulateTouch: PropTypes.number,
    paginationShow: PropTypes.number,
    paginationClickable: PropTypes.number,
    autoPlay: PropTypes.bool,
    autoplayDelay: PropTypes.number,
    storeId: PropTypes.number
});

export const BrandSliderItemType = PropTypes.shape({
    alt: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
});

export const BrandListParamsType = PropTypes.shape({
    columns: PropTypes.number,
    displayZero: PropTypes.number,
    filterDisplayAll: PropTypes.number,
    imageHeight: PropTypes.number,
    imageWidth: PropTypes.number,
    showCount: PropTypes.number,
    showFilter: PropTypes.number,
    showImages: PropTypes.number,
    showSearch: PropTypes.number,
    storeId: PropTypes.number
});

export const BrandListComponentParamsType = PropTypes.shape({
    columns: PropTypes.number,
    displayZero: PropTypes.bool,
    filterDisplayAll: PropTypes.number,
    imageHeight: PropTypes.number,
    imageWidth: PropTypes.number,
    isShowCount: PropTypes.bool,
    isShowFilter: PropTypes.bool,
    isShowImages: PropTypes.bool,
    isShowSearch: PropTypes.bool,
    storeId: PropTypes.number
});

export const BrandListParamsDefaultProps = {
    imageHeight: '100px',
    showCount: true,
    showFilter: true,
    showSearch: true
};

export const BrandsType = PropTypes.shape({
    alt: PropTypes.string,
    brandId: PropTypes.number.isRequired,
    cnt: PropTypes.number.isRequired,
    description: PropTypes.string,
    image: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    letter: PropTypes.string.isRequired,
    short_description: PropTypes.string,
    url: PropTypes.string.isRequired
}).isRequired;
