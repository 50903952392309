/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { BUTTON_ALL_BRANDS_TITLE } from '../component/BrandsSortByLetters/BrandsSortByLetters.config';

export const STORE_CODES = {
    fr: 'fr',
    en: 'en'
};

export const STORE_CODES_MAP = {
    [STORE_CODES.en]: '0',
    [STORE_CODES.fr]: '1'
};

/** @namespace Scandiweb/AmastyShopbyBrand/Util/Brands/containAnyLetter */
export const containAnyLetter = (letter, letterList) => letterList?.includes(letter);

/** @namespace Scandiweb/AmastyShopbyBrand/Util/Brands/filterBrandListBySearchAndLetter */
export const filterBrandListBySearchAndLetter = (brandList, search, sort) => {
    const lowSearch = search.toLowerCase();
    const lowSort = sort.toLowerCase();

    return brandList?.filter((item) => {
        const lowLabel = item.label.toLowerCase();

        if (lowLabel.indexOf(lowSearch) === -1) {
            return false;
        }

        if (sort === BUTTON_ALL_BRANDS_TITLE) {
            return true;
        }

        return lowLabel[0] === lowSort;
    });
};

/** @namespace Scandiweb/AmastyShopbyBrand/Util/Brands/filterLetters */
export const filterLetters = (brandList) => Array.from(new Set(brandList?.map((item) => item.letter)));

/** @namespace Scandiweb/AmastyShopbyBrand/Util/Brands/getCurrentStoreBrandsConfig */
export const getCurrentStoreBrandsConfig = (availableStores, storeCode) => availableStores[STORE_CODES_MAP[storeCode]];
