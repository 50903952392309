/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Loader from 'SourceComponent/Loader';
import SearchIcon from 'SourceComponent/SearchIcon';

import './BrandsSearch.style';

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandsSearch/Component */
export class BrandsSearchComponent extends PureComponent {
    static propTypes = {
        handleChange: PropTypes.func.isRequired
    };

    renderOverlayFallback() {
        return <Loader isLoading />;
    }

    renderSearchIcon() {
        return (
            <div
              block="BrandsSearch"
              elem="SearchIcon"
              aria-label={ __('Search') }
            >
                <SearchIcon />
            </div>
        );
    }

    renderInput() {
        const { handleChange } = this.props;

        return (
            <input
              block="BrandsSearch"
              elem="Input"
              onChange={ handleChange }
              placeholder={ __('Search brand here...') }
              autoComplete="off"
              aria-label={ __('Search') }
            />
        );
    }

    renderSearch() {
        return (
            <div
              block="BrandsSearch"
              elem="SearchInnerWrapper"
            >
                { this.renderInput() }
                { this.renderSearchIcon() }
            </div>
        );
    }

    render() {
        return (
            <div block="BrandsSearch">
                { this.renderSearch() }
            </div>
        );
    }
}

export default BrandsSearchComponent;
